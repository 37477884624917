import React, { useRef } from 'react'
import Skill from '../elements/skill'
import Separator from '../elements/separator'
import useOnScreen from '../hooks/useOnScreen'

const Skills = () => {
  const ref = useRef();
  useOnScreen(ref, 2, 0);

  const calculateYears = (year) => {
    const currentYear = new Date().getFullYear();
    const calculated = currentYear - year;
    return calculated > 5 ? "5+" : calculated;
  }

  return (
    <section className="skills">
      <div className="container">
        <div className="data-wrapper">
          <div className="data">
            <div className="head">
              <h3 className="main-header">Skills</h3>
            </div>
            <Separator size="large" />
            <div className="regular-content">
              <h3 ref={ref} className="section-title">Main tech stack</h3>
              <div className="skills-list primary">
                <Skill skillName="DotNet" skillTime={calculateYears(2018)} skillProgress="80" skillIcon="devicon-dotnetcore-plain" />
                <Skill skillName="C#" skillTime={calculateYears(2018)} skillProgress="90" skillIcon="devicon-csharp-plain" />
                <Skill skillName="MicrosoftSQL" skillTime={calculateYears(2018)} skillProgress="70" skillIcon="devicon-microsoftsqlserver-plain" />
                <Skill skillName="JavaScript" skillTime={calculateYears(2018)} skillProgress="80" skillIcon="devicon-javascript-plain" />
                <Skill skillName="TypeScript" skillTime="3" skillProgress="80" skillIcon="devicon-typescript-plain" />
                <Skill skillName="React" skillTime="3" skillProgress="90" skillIcon="devicon-react-plain" />
                <Skill skillName="HTML5 + Sass / CSS3" skillTime={calculateYears(2015)} skillProgress="100" skillIcon="devicon-html5-plain" />
                <Skill skillName="Git" skillTime={calculateYears(2018)} skillProgress="90" skillIcon="devicon-git-plain" />
                <Skill skillName="Terraform" skillTime={calculateYears(2022)} skillProgress="60" skillIcon="devicon-terraform-plain" />
                <Skill skillName="Azure" skillTime={calculateYears(2022)} skillProgress="40" skillIcon="devicon-azure-plain" />
              </div>
              <h3 className="section-title">Additional tech stack</h3>
              <div className="skills-list additional">
                <Skill skillName="PHP" skillTime="5" skillProgress="90" skillIcon="devicon-php-plain" />
                <Skill skillName="Laravel" skillTime={calculateYears(2019)} skillProgress="90" skillIcon="devicon-laravel-plain" />
                <Skill skillName="MySQL" skillTime={calculateYears(2016)} skillProgress="80" skillIcon="devicon-mysql-plain" />
                <Skill skillName="Vue.js" skillTime="3" skillProgress="80" skillIcon="devicon-vuejs-plain" />
                <Skill skillName="Docker + K8s" skillTime="2" skillProgress="70" skillIcon="devicon-docker-plain" />

              </div>

            </div>
          </div>
        </div>
      </div>
    </section >
  );
}

export default Skills