import React from 'react';

const Separator = ({ size }) => {
  return (
    <>
      <div className={`separator ${size}`}>
        <div className="wrapper-1">
          <div className="line"></div>
        </div>
        <div className="wrapper-2">
          <div className="line"></div>
        </div>
      </div>
    </>
  );
}

export default Separator