import { useContext, useEffect, useState } from "react"
import { SectionContext } from "../context/sectionContext"

export const useLandingAnimation = () => {
  const activeStatus = 'active'
  const { currentSection } = useContext(SectionContext);
  const [decorationStatus, setDecorationStatus] = useState('')

  const changeDecorationStatus = () => {
    setDecorationStatus('')

    if (currentSection == 0)
      setDecorationStatus(activeStatus)
  }

  useEffect(() => {
    changeDecorationStatus();
  }, [currentSection])

  return decorationStatus;
}

export default useLandingAnimation;