import React, { useState, useContext } from 'react'
import { SectionContext } from '../context/sectionContext'
import { SatelliteContext } from '../context/satelliteContext'

const ThemeSwitcher = () => {
  const { setTheme } = useContext(SectionContext);
  const {
    initialCoords,
    setHaloOneStyles, setHaloTwoStyles,
    setHaloThreeStyles, setHaloFourStyles,
    setHaloFiveStyles, setCoreStyles } = useContext(SatelliteContext)

  const iconName = {
    light: 'sunny-outline',
    dark: 'moon-outline'
  }
  const bodyElement = document.querySelector('body');
  const [themeIcon, setThemeIcon] = useState(iconName.dark);
  const useThemeSwitchToggle = () => {
    const currentTheme = bodyElement.getAttribute('data-theme');
    const newTheme = currentTheme == 'dark' ? 'light' : 'dark';
    bodyElement.setAttribute('data-theme', newTheme);


    setThemeIcon(iconName[newTheme]);
    setTheme(newTheme);
    setHaloOneStyles(initialCoords),
    setHaloTwoStyles(initialCoords),
    setHaloThreeStyles(initialCoords),
    setHaloFourStyles(initialCoords),
    setHaloFiveStyles(initialCoords),
    setCoreStyles(initialCoords)
  }

  return (
    <div className="theme_switcher" onClick={() => useThemeSwitchToggle()}>
      <ion-icon name={`${themeIcon}`}></ion-icon>
      <div className="toggle"></div>
    </div >
  );
}

export default ThemeSwitcher;