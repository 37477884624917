import React, { useContext, useEffect } from 'react'
import useLandingAnimation from '../hooks/useLandingAnimation'
import useLayoutEffect from '../hooks/useLayoutEffect'
import { SectionContext } from '../context/sectionContext'
import { SatelliteContext } from '../context/satelliteContext'

const Satellite = () => {
  const { mouseCoords, theme } = useContext(SectionContext)
  const {
    haloOneStyles, setHaloOneStyles,
    haloTwoStyles, setHaloTwoStyles,
    haloThreeStyles, setHaloThreeStyles,
    haloFourStyles, setHaloFourStyles,
    haloFiveStyles, setHaloFiveStyles,
    coreStyles, setCoreStyles } = useContext(SatelliteContext)
  const decorationStatus = useLandingAnimation();
  const currentWidth = useLayoutEffect();

  const createAnimationStyles = () => {
    const widthThreshold = 576;

    if (currentWidth < widthThreshold)
      return;

    const coordsXHaloOne = transitionCalculator(mouseCoords.x, 4)
    const coordsYHaloOne = transitionCalculator(mouseCoords.y, 3)

    const coordsXHaloTwo = transitionCalculator(mouseCoords.x, 3.8)
    const coordsYHaloTwo = transitionCalculator(mouseCoords.y, 2.8)

    const coordsXHaloThree = transitionCalculator(mouseCoords.x, 3.6)
    const coordsYHaloThree = transitionCalculator(mouseCoords.y, 2.6)

    const coordsXHaloFour = transitionCalculator(mouseCoords.x, 3.4)
    const coordsYHaloFour = transitionCalculator(mouseCoords.y, 2.4)

    const coordsXHaloFive = transitionCalculator(mouseCoords.x, 3.2)
    const coordsYHaloFive = transitionCalculator(mouseCoords.y, 2.2)

    const coordsXCore = transitionCalculator(mouseCoords.x, 3)
    const coordsYCore = transitionCalculator(mouseCoords.y, 2)

    if (theme == 'dark')
      setHaloOneStyles({ transform: `translateX(${coordsXHaloOne}px) translateY(-${coordsYHaloOne}px)` });

    setHaloTwoStyles({ transform: `translateX(${coordsXHaloTwo}px) translateY(-${coordsYHaloTwo}px)` });
    setHaloThreeStyles({ transform: `translateX(${coordsXHaloThree}px) translateY(-${coordsYHaloThree}px)` });
    setHaloFourStyles({ transform: `translateX(${coordsXHaloFour}px) translateY(-${coordsYHaloFour}px)` });
    setHaloFiveStyles({ transform: `translateX(${coordsXHaloFive}px) translateY(-${coordsYHaloFive}px)` });
    setCoreStyles({ transform: `translateX(${coordsXCore}px) translateY(-${coordsYCore}px)` });
  }

  useEffect(() => {
    createAnimationStyles();
  }, [mouseCoords])

  return (
    <div className={`satellite ${decorationStatus}`}>
      <div className="halo-1" style={haloOneStyles}></div>
      <div className="halo-2" style={haloTwoStyles}></div>
      <div className="halo-3" style={haloThreeStyles}></div>
      <div className="halo-4" style={haloFourStyles}></div>
      <div className="halo-5" style={haloFiveStyles}></div>
      <div className="core" style={coreStyles}>
        <div className="maria-1"></div>
        <div className="maria-2"></div>
        <div className="maria-3"></div>
      </div>
    </div>
  );
}

const transitionCalculator = (value, mutator) => {
  const strength = 13;
  return value / (strength * mutator)
}

export default Satellite