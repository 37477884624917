import React, { createContext, useState } from 'react'

export const SectionContext = createContext()
const SectionContextProvider = (props) => {

  const [theme, setTheme] = useState('dark');
  const [currentSection, setCurrentSection] = useState('initial');
  const [scrolledY, setScrolledY] = useState(0);
  const [sectionsOffset, setSectionsOffset] = useState(
    [
      [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]
    ]
  );
  const [screenHeight, setScreenHeight] = useState(0);
  const [mouseCoords, setMouseCoords] = useState({ x: 0, y: 0 })

  return (
    <SectionContext.Provider value={{
      currentSection, setCurrentSection,
      theme, setTheme,
      screenHeight, setScreenHeight,
      scrolledY, setScrolledY,
      sectionsOffset, setSectionsOffset,
      mouseCoords, setMouseCoords
    }}> {props.children} </SectionContext.Provider>
  );
}

export default SectionContextProvider;