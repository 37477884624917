import { useEffect, useContext, useRef } from 'react'
import { SectionContext } from '../context/sectionContext'

const useOnScreen = (ref, section, threshold) => {
  const { setCurrentSection } = useContext(SectionContext)

  useEffect(() => {
    const observerOptions = {
      threshold: threshold
    }
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0]
      if (entry.isIntersecting)
        setCurrentSection(section);
    }, observerOptions)
    observer.observe(ref.current);
  }, [])
}

export default useOnScreen;