import React, { useState, useEffect } from 'react'

const useLayoutEffect = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const setWindowDimensions = () => setWindowWidth(window.innerWidth)
  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions)
    }
  }, [])
  return windowWidth;
}

export default useLayoutEffect;