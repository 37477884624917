import React, { useContext, useState, useEffect } from 'react'
import { SectionContext } from '../context/sectionContext'

const useSideNavScroll = () => {
  const { currentSection } = useContext(SectionContext);
  const sectionActiveClassesInitial = ['', '', '', '', '']
  const [sectionActiveClasses, setSectionActiveClasses] = useState(sectionActiveClassesInitial)
  const activeClassName = 'active'

  const changeActiveSection = () => {
    let tempSectionActiveClasses = sectionActiveClassesInitial;
    tempSectionActiveClasses[currentSection] = activeClassName
    setSectionActiveClasses(tempSectionActiveClasses)
  }

  useEffect(() => {
    changeActiveSection()
  }, [currentSection])

  return sectionActiveClasses;
}

export default useSideNavScroll;