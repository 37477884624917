import React, { useContext, useState, useEffect } from 'react'
import { SectionContext } from '../context/sectionContext'

const useSideMarkerScroll = () => {
  const { currentSection } = useContext(SectionContext);
  const sectionActiveClassesInitial = ['', '', '', '', '']
  const activeClassName = 'active'
  const [scrollMarkerActivityClass, setScrollMarkerActivityClass] = useState(activeClassName)

  const changeScrollMarker = () => {
    const lastScreenNumber = (sectionActiveClassesInitial.length - 1)
    setScrollMarkerActivityClass(activeClassName)
    if (currentSection == lastScreenNumber)
      setScrollMarkerActivityClass('')
  }

  useEffect(() => {
    changeScrollMarker()
  }, [currentSection])

  return scrollMarkerActivityClass;
}

export default useSideMarkerScroll;