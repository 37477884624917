import React, { useRef } from 'react'
import Separator from '../elements/separator'
import useOnScreen from '../hooks/useOnScreen'

const About = () => {
  const ref = useRef();
  useOnScreen(ref, 1, 0.2);

  return (
    <section className="about">
      <div className="container">
        <div className="data-wrapper">
          <div className="data">
            <div className="head">
              <h3 className="main-header">Greetings</h3>
            </div>
            <Separator size="large" />
            <div ref={ref} className="regular-content">
              <p>I'm a software engineer who has a keen interest in all things related to development.</p>
              <p>I got my first job as a developer in 2011 and spent some time building CMS-based websites. Today my area of expertise is a development of enterprise web applications and SaaS products.</p>
              <p>Aside from having extensive knowledge of recognized technical standards, I am conversant with modern software development practices.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About