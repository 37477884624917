import React, { useContext, useEffect, useState } from 'react'
import useLandingAnimation from '../hooks/useLandingAnimation';
import { SectionContext } from '../context/sectionContext'

const Clouds = ({ cloudClass }) => {
  const cloudsStatus = useLandingAnimation();
  const { mouseCoords } = useContext(SectionContext)
  const [cloudStyles, setCloudStyles] = useState()

  const createAnimationStyles = () => {
    const coordsX = transitionCalculator(mouseCoords.x, 3)
    const coordsY = transitionCalculator(mouseCoords.y, 2)
    setCloudStyles({ transform: `translateX(${coordsX}px) translateY(-${coordsY}px)` });
  }

  const transitionCalculator = (value, mutator) => {
    const strength = 8;
    return value / (strength * mutator)
  }

  useEffect(() => {
    createAnimationStyles();
  }, [mouseCoords])

  return (
    <div className={`${cloudClass} ${cloudsStatus}`} style={ cloudStyles }>
      <div className="inner">
        <div className="row-1">
          <div className="element-1 medium"></div>
          <div className="element-2 tiny"></div>
        </div>
        <div className="row-2"></div>
        <div className="row-3">
          <div className="element-3 small"></div>
          <div className="element-4 huge"></div>
        </div>
        <div className="row-4 half">
          <div className="con-1 row-4 tiny"></div>
        </div>
        <div className="row-5">
          <div className="element-6 big"></div>
          <div className="element-7 small"></div>
        </div>
        <div className="row-6 half">
          <div className="con-2 small"></div>
        </div>
        <div className="row-7">
          <div className="element-9 huge"></div>
        </div>
        <div className="row-8">
          <div className="con-3"></div>
        </div>
        <div className="row-9">
          <div className="element-10 huge"></div>
        </div>
      </div>
    </div>
  );
}

export default Clouds;