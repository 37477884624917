import React, { useEffect } from 'react';
import Clouds from './elements/clouds';
import Satellite from './elements/satellite';
import Stars from './elements/stars';
import './styles/bootstrap.css';
import ThemeSwitcher from './elements/themeSwitcher';
import City from './elements/city';
import Landing from './sections/landing';
import About from './sections/about';
import Skills from './sections/skills';
import Work from './sections/work';
import Contact from './sections/contact';
import Side from './elements/side';
import SectionContextProvider from './context/sectionContext';
import SatelliteContextProvider from './context/satelliteContext';

const App = () => {
  return (
    <>
      <SectionContextProvider>
        <SatelliteContextProvider>
          <ThemeSwitcher />
          <Side />
          <Satellite />
          <Clouds cloudClass={"cloud-1"} />
          <Clouds cloudClass={"cloud-2"} />
          <Clouds cloudClass={"cloud-3"} />
          <Stars />
          <City />
          <Landing />
          <About />
          <Skills />
          <Work />
          <Contact />
        </SatelliteContextProvider>
      </SectionContextProvider>
    </>
  );
};
export default App;