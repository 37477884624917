import React, { createContext, useState } from 'react'
export const SatelliteContext = createContext();

const SatelliteContextProvider = (props) => {
  const initialCoords = {x: 0, y: 0}

  const [haloOneStyles, setHaloOneStyles] = useState(initialCoords);
  const [haloTwoStyles, setHaloTwoStyles] = useState(initialCoords);
  const [haloThreeStyles, setHaloThreeStyles] = useState(initialCoords);
  const [haloFourStyles, setHaloFourStyles] = useState(initialCoords);
  const [haloFiveStyles, setHaloFiveStyles] = useState(initialCoords);
  const [coreStyles, setCoreStyles] = useState(initialCoords);

  return (
    <SatelliteContext.Provider value={{
      initialCoords,
      haloOneStyles, setHaloOneStyles,
      haloTwoStyles, setHaloTwoStyles,
      haloThreeStyles, setHaloThreeStyles,
      haloFourStyles, setHaloFourStyles,
      haloFiveStyles, setHaloFiveStyles,
      coreStyles, setCoreStyles
    }}>
      {props.children}
    </SatelliteContext.Provider>
  )
}

export default SatelliteContextProvider;