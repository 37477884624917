import React, { useRef, useState, useContext } from 'react'
import Separator from '../elements/separator'
import useOnScreen from '../hooks/useOnScreen'
import {SectionContext} from '../context/sectionContext'

const Landing = () => {
  const ref = useRef();
  useOnScreen(ref, 0, 0);
  const {setMouseCoords} = useContext(SectionContext);
  const handleMouseMove = (e) => setMouseCoords({x: `-${e.screenX}`, y: e.screenY})

  return (
    <section className="landing" onMouseMove={(e) => handleMouseMove(e)}>
      <div className="container">
        <div className="data-wrapper">
          <div className="head">
            <h3 className="name main-header" ref={ref}>DMITRII<br />CHURIKOV</h3>
            <Separator size="large" />
            <div className="sub-title">fullstack developer / software engineer</div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Landing