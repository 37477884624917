import React from 'react'
import useLandingAnimation from '../hooks/useLandingAnimation'

const Stars = () => {
  const decorationStatus = useLandingAnimation();
  const stars = {
    color: '#fff',
    radius: {
      xxs: 1,
      xs: 1.4,
      s: 1.5,
      m: 1.8,
      l: 2,
      xl: 2.2,
      xxl: 2.3
    }
  }
  return (
    <div className={`stars ${decorationStatus}`}>
      <svg className="layer-1">
        <circle cx="2%" cy="3%" r={stars.radius.xs} fill={stars.color} />
        <circle cx="8%" cy="22%" r={stars.radius.xxl} fill={stars.color} />
        <circle cx="13%" cy="87%" r={stars.radius.xs} fill={stars.color} />
        <circle cx="17%" cy="46%" r={stars.radius.s} fill={stars.color} />
        <circle cx="27%" cy="18%" r={stars.radius.m} fill={stars.color} />
        <circle cx="33%" cy="47%" r={stars.radius.l} fill={stars.color} />
        <circle cx="37%" cy="94%" r={stars.radius.xs} fill={stars.color} />
        <circle cx="47%" cy="15%" r={stars.radius.xxs} fill={stars.color} />
        <circle cx="51%" cy="72%" r={stars.radius.l} fill={stars.color} />
        <circle cx="55%" cy="67%" r={stars.radius.m} fill={stars.color} />
        <circle cx="59%" cy="5%" r={stars.radius.s} fill={stars.color} />
        <circle cx="61%" cy="82%" r={stars.radius.s} fill={stars.color} />
        <circle cx="67%" cy="35%" r={stars.radius.m} fill={stars.color} />
        <circle cx="72%" cy="42%" r={stars.radius.xxs} fill={stars.color} />
        <circle cx="79%" cy="2%" r={stars.radius.s} fill={stars.color} />
        <circle cx="84%" cy="67%" r={stars.radius.xs} fill={stars.color} />
        <circle cx="86%" cy="22%" r={stars.radius.m} fill={stars.color} />
        <circle cx="92%" cy="57%" r={stars.radius.xs} fill={stars.color} />
        <circle cx="95%" cy="70%" r={stars.radius.s} fill={stars.color} />
      </svg>
      <svg className="layer-2">
        <circle cx="3%" cy="9%" r={stars.radius.xxl} fill={stars.color} />
        <circle cx="10%" cy="55%" r={stars.radius.xs} fill={stars.color} />
        <circle cx="13%" cy="18%" r={stars.radius.m} fill={stars.color} />
        <circle cx="14%" cy="67%" r={stars.radius.xs} fill={stars.color} />
        <circle cx="24%" cy="58%" r={stars.radius.xxl} fill={stars.color} />
        <circle cx="29%" cy="18%" r={stars.radius.xs} fill={stars.color} />
        <circle cx="32%" cy="27%" r={stars.radius.xs} fill={stars.color} />
        <circle cx="39%" cy="98%" r={stars.radius.l} fill={stars.color} />
        <circle cx="47%" cy="3%" r={stars.radius.m} fill={stars.color} />
        <circle cx="51%" cy="18%" r={stars.radius.xs} fill={stars.color} />
        <circle cx="55%" cy="37%" r={stars.radius.s} fill={stars.color} />
        <circle cx="63%" cy="19%" r={stars.radius.xs} fill={stars.color} />
        <circle cx="65%" cy="38%" r={stars.radius.m} fill={stars.color} />
        <circle cx="74%" cy="26%" r={stars.radius.s} fill={stars.color} />
        <circle cx="78%" cy="88%" r={stars.radius.xs} fill={stars.color} />
        <circle cx="85%" cy="38%" r={stars.radius.m} fill={stars.color} />
        <circle cx="88%" cy="4%" r={stars.radius.s} fill={stars.color} />
        <circle cx="92%" cy="25%" r={stars.radius.s} fill={stars.color} />
        <circle cx="94%" cy="24%" r={stars.radius.m} fill={stars.color} />
      </svg>
    </div>
  );
}

export default Stars;