import React from 'react'

const City = () => {
  return (
    <div className="city">
      <div className="building-1">
        <div className="roof slope"></div>
        <div className="main"></div>
      </div>
      <div className="building-2">
        <div className="level-1"></div>
        <div className="main"></div>
      </div>
      <div className="building-3">
        <div className="level-5"></div>
        <div className="level-4"></div>
        <div className="level-3"></div>
        <div className="level-2"></div>
        <div className="level-1"></div>
        <div className="main"></div>
      </div>
      <div className="building-4">
        <div className="roof even"></div>
        <div className="level-1"></div>
        <div className="main"></div>
      </div>
      <div className="building-5">
        <div className="main"></div>
      </div>
      <div className="pre-ground"></div>
      <div className="ground"></div>
    </div>
  );
}

export default City