import React from 'react'

const Skill = ({ skillName, skillTime, skillProgress, skillIcon }) => {
  return (
    <div className="skill">
      <span className={skillIcon}></span>
      <div className="description">
        <div className="meta">
          <div>
            <span className="name">{skillName}</span>
            <span className="time">{skillTime} years</span>
          </div>
          <div className="percentage">
            <span>{skillProgress}%</span>
          </div>
        </div>
        <div className="progress">
          <div className={`bar w-${skillProgress}`}></div>
        </div>
      </div>
    </div>
  );
}

export default Skill