import React from 'react'
import useSideNavScroll from '../hooks/useSideNavScroll'
import useSideMarkerScroll from '../hooks/useSideMarkerScroll'

const Side = () => {
  const sectionActiveClasses = useSideNavScroll();
  const scrollMarkerActivityClass = useSideMarkerScroll();

  return (
    <aside className="side-line">
      <nav className="side-nav">
        <div className={sectionActiveClasses[0]}></div>
        <div className={sectionActiveClasses[1]}></div>
        <div className={sectionActiveClasses[2]}></div>
        <div className={sectionActiveClasses[3]}></div>
        <div className={sectionActiveClasses[4]}></div>
      </nav >
      <div className={`scrolldown ${scrollMarkerActivityClass}`}>
        <p>Scrolldown</p>
        <div className="line"></div>
      </div>
    </aside >
  );
}

export default Side