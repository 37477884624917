import React, { useRef } from 'react'
import Separator from '../elements/separator'
import useOnScreen from '../hooks/useOnScreen'

const Contact = () => {
  const ref = useRef();
  useOnScreen(ref, 4, 0);

  return (
    <section className="contact">
      <div className="container">
        <div className="data-wrapper">
          <div className="data">
            <div className="head">
              <h3 className="main-header">Get in touch</h3>
            </div>
            <Separator size="large" />
            <div ref={ref} className="regular-content">
              <div className="contact-details">
                <div className="qr"></div>
                <div className="links">
                  <div className="w-icon">
                    <ion-icon name="logo-linkedin"></ion-icon>
                    <a href="https://linkedin.com/in/churikoff" target="_blank" className="text">Linkedin</a>
                  </div>
                  <div className="w-icon">
                    <ion-icon name="mail"></ion-icon>
                    <a href="mailto:indigoblakework@gmail.com?subject=Greetings!" target="_blank" className="text">indigoblakework@gmail.com</a>
                  </div>
                  <div className="w-icon">
                    <ion-icon name="git-branch"></ion-icon>
                    <a href="https://github.com/churikoff" target="_blank" className="text">Github</a>
                  </div>
                  <div className="w-icon">
                    <ion-icon name="location"></ion-icon>
                    <p className="text">Wellington, New Zealand</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact