import React, { useRef } from 'react'
import Separator from '../elements/separator'
import useOnScreen from '../hooks/useOnScreen'

const Work = () => {
  const ref = useRef();
  useOnScreen(ref, 3, 0);

  return (
    <section className="work">
      <div className="container">
        <div className="data-wrapper">
          <div className="data">
            <div className="head">
              <h3 className="main-header">Work experience</h3>
            </div>
            <Separator size="large" />
            <div className="regular-content">
              <div ref={ref} className="timeline">
                <div className="entry active" onClick={changeWorkEntry}>
                  <div className="line"><div className="dot"></div></div>
                  <div className="title">
                    <p className="dates">October 2022 — Present</p>
                    <p className="company">Z Energy</p>
                    <p className="position">Senior Software Developer</p>
                  </div>
                  <div className="details">
                    <ul>
                      <li>Work on various applications running on DotNet and NodeJS.</li>
                    </ul>
                  </div>
                </div>
                <div className="entry" onClick={changeWorkEntry}>
                  <div className="line"><div className="dot"></div></div>
                  <div className="title">
                    <p className="dates">March 2020 — October 2022</p>
                    <p className="company">HealthSafe LTD</p>
                    <p className="position">Senior Fullstack Developer / Engineering Lead</p>
                  </div>
                  <div className="details">
                    <ul>
                      <li>Engineering lead of several enterprise SaaS systems.</li>
                      <li>Handle the development of high-performance websocket-based application with various integration with external third party systems and services.</li>
                      <li>Move the legacy enterprise monolithic project to microservices architecture with Docker, Kubernetes and RabbitMQ.</li>
                    </ul>
                  </div>
                </div>
                <div className="entry" onClick={changeWorkEntry}>
                  <div className="line"><div className="dot"></div></div>
                  <div className="title">
                    <p className="dates">September 2019 — March 2020</p>
                    <p className="company">CyberWorkshop</p>
                    <p className="position">Senior Fullstack Developer</p>
                  </div>
                  <div className="details">
                    <ul>
                      <li>Development of custom applications based on integrations with business management, inventory management and payment systems like Cin7, Propella, Stripe etc.</li>
                      <li>Adjustments and functionality enhancements of existing web applications.</li>
                    </ul>
                  </div>
                </div>
                <div className="entry" onClick={changeWorkEntry}>
                  <div className="line"><div className="dot"></div></div>
                  <div className="title">
                    <p className="dates">December 2016 — September 2019</p>
                    <p className="company">FourthMedia​</p>
                    <p className="position">Fullstack Developer</p>
                  </div>
                  <div className="details">
                    <ul>
                      <li>Development of custom web applications and refactoring of existing legacy web apps.</li>
                    </ul>
                  </div>
                </div>
                <div className="entry" onClick={changeWorkEntry}>
                  <div className="line"><div className="dot"></div></div>
                  <div className="title">
                    <p className="dates">2013 — December 2016</p>
                    <p className="company">Freelance</p>
                    <p className="position">Fullstack Developer</p>
                  </div>
                  <div className="details">
                    <ul>
                      <li>Work on various projects of different size and complexity.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const changeWorkEntry = (e) => {
  const activeClass = 'active';
  const allEntries = document.querySelectorAll('section.work .entry');
  allEntries.forEach(e => e.classList.remove(activeClass));

  e.target.closest('.entry').classList.add(activeClass);
}

export default Work
